var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { height: "700", width: "700" },
          on: {
            "click:outside": function () {
              this$1.isUpdating = false
              this$1.isUpdatingId = null
            },
          },
          model: {
            value: _vm.isUpdating,
            callback: function ($$v) {
              _vm.isUpdating = $$v
            },
            expression: "isUpdating",
          },
        },
        [
          _vm.isUpdating
            ? _c("ComponentUpdateCompanyHRDC", {
                attrs: { id: this.isUpdatingId },
                on: {
                  finish: function ($event) {
                    return _vm.fetch()
                  },
                  close: function () {
                    this$1.isUpdating = false
                    this$1.isUpdatingId = null
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { height: "700", width: "700" },
          on: {
            "click:outside": function () {
              this$1.isAdding = false
            },
          },
          model: {
            value: _vm.isAdding,
            callback: function ($$v) {
              _vm.isAdding = $$v
            },
            expression: "isAdding",
          },
        },
        [
          _vm.isAdding
            ? _c("ComponentAddCompanyHRDC", {
                attrs: { companyId: this.companyId },
                on: {
                  finish: function ($event) {
                    return _vm.fetch()
                  },
                  close: function () {
                    this$1.isAdding = false
                    this$1.isAddingCompanyId
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.data != null && !_vm.api.isLoading
        ? _c("div", [
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _c(
                "div",
                {
                  staticClass: "text-h6 ",
                  staticStyle: { "font-weight": "600" },
                },
                [_vm._v(" HRDC ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.openNewModal },
                    },
                    [_vm._v(" Add ")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              _vm._l(this.data, function (item, index) {
                return _c(
                  "v-row",
                  { key: index },
                  [
                    _c("v-col", { staticClass: "pb-3" }, [
                      _c("br"),
                      _c("div", { staticClass: "my-2" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c("p", { staticClass: "text-h8" }, [
                              _vm._v(" Invoice : "),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.hrdc_invoice != null
                                      ? item.hrdc_invoice
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c("p", { staticClass: "text-h8" }, [
                              _vm._v(" Year : "),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(item.year != null ? item.year : "-") +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c("p", { staticClass: "text-h8" }, [
                              _vm._v(" Year(s) of Subscription : "),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.year_of_subscription != null
                                      ? item.year_of_subscription
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c("p", { staticClass: "text-h8" }, [
                              _vm._v(" Invoice Sent Date : "),
                            ]),
                            item.hrdc_invoice_sent_date != null
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.convertTimeToDay(
                                          item.hrdc_invoice_sent_date
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("p", [_vm._v(" - ")]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openUpdateModal(item.id)
                                  },
                                },
                              },
                              [_vm._v(" Update ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }