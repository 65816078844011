var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ANotAllowed", {
        attrs: { notAllowed: _vm.notAllowed },
        on: { close: _vm.closeNotAllowed },
      }),
      _c("ANotAllowed", {
        attrs: { notAllowed: _vm.notAllowedAction },
        on: { close: _vm.closeNotAllowedAction },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingVerification },
        on: {
          confirm: _vm.updateCompanyVerification,
          cancel: _vm.cancelUpdateCompanyVerification,
        },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingRefundStatus },
        on: {
          confirm: _vm.updateRefundStatus,
          cancel: _vm.cancelUpdateRefundStatus,
        },
      }),
      this.subscription == null && this.isLoadingSubscription == false
        ? _c("AWarning", { attrs: { message: _vm.warningNoSubscription } })
        : _vm._e(),
      _c("AError", { attrs: { api: this.api } }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function () {
            _vm.api.isSuccesful = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-menu",
            {
              staticStyle: { padding: "0px" },
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                "min-width": "150px",
                                "min-height": "50px",
                                color: "primary",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(" Actions "),
                          _vm.actionDrop
                            ? _c("v-icon", [_vm._v(" mdi-arrow-up ")])
                            : _c("v-icon", [_vm._v(" mdi-arrow-down ")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.actionDrop,
                callback: function ($$v) {
                  _vm.actionDrop = $$v
                },
                expression: "actionDrop",
              },
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.Actions, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      item.rolesAllowed.some(function (role) {
                        return _vm.$store.getters.getRole.includes(role)
                      })
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.actions(item.action)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mx-2", attrs: { size: "20" } },
                                [_vm._v(" " + _vm._s(item.icon) + " ")]
                              ),
                              _c("div", [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "v-list-item",
                            { on: { click: _vm.showNotAllowed } },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mx-2", attrs: { size: "20" } },
                                [_vm._v(" " + _vm._s(item.icon) + " ")]
                              ),
                              _c("div", [
                                _vm._v(" " + _vm._s(item.name) + " "),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isLoadingCompany
        ? _c("v-skeleton-loader", {
            ref: "skeleton",
            staticClass: "mt-4",
            attrs: { type: "table" },
          })
        : _vm._e(),
      !_vm.isLoadingCompany
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c("v-toolbar", { attrs: { color: "secondary" } }, [
                _c("div", { staticClass: "white--text text-h5" }, [
                  _vm._v(" Company "),
                ]),
              ]),
              this.company
                ? _c(
                    "v-card",
                    { staticClass: "pa-5" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pr-5",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c("div", { staticClass: "mx-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-start  text-h6 my-4",
                                    staticStyle: { "font-weight": "600" },
                                  },
                                  [_vm._v(" Company Detail ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Name : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.company.name) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                                this.allowedRoleUpdateStatus.some(function (
                                  role
                                ) {
                                  return this$1.$store.getters.getRole.includes(
                                    role
                                  )
                                })
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c("p", { staticClass: "text-h7" }, [
                                          _vm._v(" Stripe : "),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.company.company_stripe_id !=
                                                  null
                                                  ? _vm.company
                                                      .company_stripe_id
                                                  : null
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" SSM : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.company.ssm_no
                                              ? _vm.company.ssm_no
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Address : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.company.address
                                              ? _vm.company.address
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Industry : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.company.industry
                                              ? _vm.company.industry
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" AAE : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.company.minutes != null
                                              ? _vm.company.minutes + " Minutes"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                this.allowedRoleUpdateStatus.some(function (
                                  role
                                ) {
                                  return this$1.$store.getters.getRole.includes(
                                    role
                                  )
                                })
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-space-start",
                                        },
                                        [
                                          _c("p", { staticClass: "text-h7" }, [
                                            _vm._v(" Status : "),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-space-start",
                                        },
                                        [
                                          _vm.isLoadingUpdateVerification
                                            ? _c("v-progress-circular", {
                                                attrs: {
                                                  size: 50,
                                                  color: "primary",
                                                  indeterminate: "",
                                                },
                                              })
                                            : _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  items:
                                                    _vm.companyVerificationOption,
                                                },
                                                on: {
                                                  change:
                                                    _vm.validateInputChangeVerification,
                                                },
                                                model: {
                                                  value:
                                                    _vm.company.verification,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.company,
                                                      "verification",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "company.verification",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  [
                                    _c("hr"),
                                    _c(
                                      "v-tabs",
                                      {
                                        staticClass: "my-3",
                                        staticStyle: { "{color": "#33BDF1}" },
                                      },
                                      [
                                        _c("v-tab", [_vm._v(" Client ")]),
                                        _c("v-tab", [_vm._v(" PIC ")]),
                                        _c("v-tab-item", [
                                          _c(
                                            "div",
                                            { staticClass: "my-2" },
                                            [
                                              _c(
                                                "v-carousel",
                                                {
                                                  attrs: {
                                                    height: "auto",
                                                    width: "auto",
                                                    continuous: false,
                                                    "hide-delimiters": "",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.client,
                                                  function (item, index) {
                                                    return _c(
                                                      "v-carousel-item",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "mx-2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Name : "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.fname
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          item.lname
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Position : "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.position
                                                                            ? item.position
                                                                            : "-"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.checkIfCanAccessBonusOffer()
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Account category : "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.acc_category
                                                                                ? item.acc_category
                                                                                : "-"
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm.checkIfCanAccessBonusOffer()
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Bonus offer : "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.bonus_offer
                                                                                ? item.bonus_offer
                                                                                : "-"
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Phone no : "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.mobile
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Email : "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.email
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Existing client : "
                                                                    ),
                                                                  ]
                                                                ),
                                                                item.existing_client !=
                                                                null
                                                                  ? _c("p", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.existing_client
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _c("p", [
                                                                      _vm._v(
                                                                        " - "
                                                                      ),
                                                                    ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Active : "
                                                                    ),
                                                                  ]
                                                                ),
                                                                item.active !=
                                                                null
                                                                  ? _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.active
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c("p", [
                                                                      _vm._v(
                                                                        " - "
                                                                      ),
                                                                    ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Gender : "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.gender
                                                                            ? item.gender
                                                                            : "-"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-space-between",
                                                              },
                                                              [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    " Referral code "
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-h7",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.referral_uuid
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("v-tab-item", [
                                          _c(
                                            "div",
                                            [
                                              _vm.staff != null
                                                ? _c(
                                                    "v-carousel",
                                                    {
                                                      attrs: {
                                                        continuous: false,
                                                        "hide-delimiters": "",
                                                        height: "auto",
                                                        width: "auto",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.staff,
                                                      function (item, index) {
                                                        return _c(
                                                          "v-carousel-item",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "pa-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Name : "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.fname
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              item.lname
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Status : "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    item.deleted_at ==
                                                                    null
                                                                      ? _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "text-h7",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Active "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              " Inactive "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Phone no : "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.mobile
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Email : "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.email
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Role : "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.role_name.replaceAll(
                                                                                "_",
                                                                                " "
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Assigned Date "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-h7",
                                                                      },
                                                                      [
                                                                        item.pic_created_at !=
                                                                        null
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.convertTimeZone(
                                                                                        item.pic_created_at
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        { staticClass: "mt-2" },
                                                        [
                                                          _c("AWarning", {
                                                            attrs: {
                                                              message:
                                                                "No Person-in-charge assigned",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("hr"),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start  mb-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-h6 my-4 mb-3",
                                        staticStyle: { "font-weight": "700" },
                                      },
                                      [_vm._v(" Payment method ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-h7 font-weight-bold",
                                      },
                                      [_vm._v(" Credit card ")]
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(" Brand : "),
                                      ]),
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.company.card_brand
                                                ? _vm.company.card_brand
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(" Last 4 digits : "),
                                      ]),
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.company.card_last_four
                                                ? _vm.company.card_last_four
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(" Expired at : "),
                                      ]),
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.company.expired
                                                ? _vm.company.expired
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("hr"),
                              _c("div", [
                                _c("div", { staticClass: "mb-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-h6 d-flex justify-start mt-4 mb-6   mb-3",
                                      staticStyle: { "font-weight": "600" },
                                    },
                                    [_vm._v(" Last subscription from Stripe ")]
                                  ),
                                  this.subscription != null
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between",
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "text-h7" },
                                              [_vm._v(" Name : ")]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "text-h7" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.subscription.plan_name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between",
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "text-h7" },
                                              [_vm._v(" Price : ")]
                                            ),
                                            _vm.subscription.total_discount !=
                                            null
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-h7 text-decoration-line-through",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " RM " +
                                                        _vm._s(
                                                          _vm.subscription.price
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm.subscription.total_discount != null
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "text-h7" },
                                                  [_vm._v(" Discount : ")]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-h7" },
                                                  [
                                                    _vm._v(
                                                      " RM " +
                                                        _vm._s(
                                                          _vm.subscription
                                                            .total_discount != 0
                                                            ? _vm.subscription.total_discount.slice(
                                                                0,
                                                                -2
                                                              )
                                                            : 0
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.subscription.total_discount != null
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "text-h7" },
                                                  [_vm._v(" Total price : ")]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-h7 font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.subscription
                                                            .amount_paid != 0
                                                            ? "RM" +
                                                                _vm.subscription.amount_paid.slice(
                                                                  0,
                                                                  -2
                                                                )
                                                            : "Non - Stripe Payment"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between",
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "text-h7" },
                                              [_vm._v(" Referral Code : ")]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "text-h7" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.subscription.coupon
                                                        ? _vm.subscription
                                                            .coupon
                                                        : "Not applicable"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between",
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "text-h7" },
                                              [_vm._v(" Subscription date : ")]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "text-h7" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.convertTimeZone(
                                                        _vm.subscription
                                                          .created_at
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.subscription.refund_status !=
                                        "Canceled"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "text-h7" },
                                                  [
                                                    _vm._v(
                                                      " Next renewal date : "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-h7" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.convertTimeZone(
                                                            _vm.subscription
                                                              .renewal_at
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex justify-end" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "text-h10",
                                                attrs: { color: "primary" },
                                                on: {
                                                  click:
                                                    _vm.readAllSubscription,
                                                },
                                              },
                                              [_vm._v(" Show all ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _c("div", [
                                        _c("span", [
                                          _vm._v("No subscription found"),
                                        ]),
                                      ]),
                                ]),
                                _c("hr"),
                              ]),
                              _vm.checkIfCanAccessCompanyFinance()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentFinance", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessCompanyFinance()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentFinance", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessSubscriptionHistory()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentSubscriptionHistory", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                      _c("hr"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessCompanyRefund()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentCompanyRefund", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                      _c("hr"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessCompanySales()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentCompanySales", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                      _c("hr"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessCompanyHRDC()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentCompanyHRDC", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                      _c("hr"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessCompanyAAE()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentCompanyAAE", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                      _c("hr"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.checkIfCanAccessCompanyFunnel()
                                ? _c(
                                    "div",
                                    [
                                      _c("ComponentCompanyFunnel", {
                                        attrs: {
                                          companyId:
                                            this.$router.history.current.params
                                              .id,
                                        },
                                      }),
                                      _c("hr"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start  mb-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-h6 my-4 mb-3",
                                        staticStyle: { "font-weight": "700" },
                                      },
                                      [_vm._v(" Payment method ")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-h7 font-weight-bold",
                                      },
                                      [_vm._v(" Credit card ")]
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(" Brand : "),
                                      ]),
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.company.card_brand
                                                ? _vm.company.card_brand
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(" Last 4 digits : "),
                                      ]),
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.company.card_last_four
                                                ? _vm.company.card_last_four
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(" Expired at : "),
                                      ]),
                                      _c("p", { staticClass: "text-h7" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.company.expired
                                                ? _vm.company.expired
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("hr"),
                                _vm.checkIfCanAccessCompanyInvoice()
                                  ? _c(
                                      "div",
                                      [
                                        _c("ComponentInvoiceCompany", {
                                          attrs: {
                                            companyId:
                                              this.$router.history.current
                                                .params.id,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("hr"),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-5",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              this.isLoadingSubscription
                                ? _c("v-skeleton-loader", {
                                    ref: "skeleton",
                                    staticClass: "ma-4",
                                    attrs: { type: "card-avatar" },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "mx-2" },
                                [
                                  _c("ComponentPrototypeCompanySpecialRemark"),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start  text-h6 my-4 mb-3",
                                      staticStyle: { "font-weight": "600" },
                                    },
                                    [_vm._v(" Renewal update status ")]
                                  ),
                                  _vm.renewalStatus != null
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pa-2",
                                            staticStyle: {
                                              "border-radius": "8px",
                                              "border-style": "solid",
                                              "border-color":
                                                "rgba(5, 123, 166, 0.2)",
                                            },
                                          },
                                          [
                                            _vm.isLoadingRenewal
                                              ? _c("v-skeleton-loader", {
                                                  ref: "skeleton",
                                                  staticClass: "ma-4",
                                                  attrs: {
                                                    type: "card-avatar",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "v-carousel",
                                              {
                                                staticClass: "pa-3",
                                                staticStyle: { height: "auto" },
                                                attrs: {
                                                  max: "5",
                                                  continuous: false,
                                                  "hide-delimiters": "",
                                                  "max-height": "350px",
                                                  "max-width": "300px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-start mb-2",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold text-h7",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              this
                                                                .totalRenewalStatus
                                                            ) +
                                                            " renewal update (2023) " +
                                                            _vm._s() +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                !_vm.isLoadingRenewal
                                                  ? _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-2  elevation-0",
                                                        attrs: {
                                                          color: "white",
                                                          height: "auto",
                                                          "max-height": "400px",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.renewalStatus,
                                                        function (item, index) {
                                                          return _c(
                                                            "v-carousel-item",
                                                            { key: index },
                                                            [
                                                              index < 5
                                                                ? _c(
                                                                    "v-sheet",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-start pa-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                index +
                                                                                  1
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                " Report by : "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.fname
                                                                                  ) +
                                                                                  " " +
                                                                                  _vm._s(
                                                                                    item.lname
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex \\justify-space-between",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                " Report date : "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "p",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.convertTimeZone(
                                                                                      item.created_at
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between mb-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                " Renewal status : "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      bottom:
                                                                                        "",
                                                                                    },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "activator",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var on =
                                                                                              ref.on
                                                                                            var attrs =
                                                                                              ref.attrs
                                                                                            return [
                                                                                              item.renewal_status ==
                                                                                              "Pending"
                                                                                                ? _c(
                                                                                                    "v-chip",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                "pending white--text",
                                                                                                            },
                                                                                                        },
                                                                                                        "v-chip",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            item.renewal_status
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              item.renewal_status ==
                                                                                              "Confirm"
                                                                                                ? _c(
                                                                                                    "v-chip",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                "green white--text",
                                                                                                            },
                                                                                                        },
                                                                                                        "v-chip",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            item.renewal_status
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              item.renewal_status ==
                                                                                              "Confirm - Pending HRDC"
                                                                                                ? _c(
                                                                                                    "v-chip",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                "green white--text",
                                                                                                            },
                                                                                                        },
                                                                                                        "v-chip",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            item.renewal_status
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              item.renewal_status ==
                                                                                              "Unreachable"
                                                                                                ? _c(
                                                                                                    "v-chip",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                "grey",
                                                                                                            },
                                                                                                        },
                                                                                                        "v-chip",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            item.renewal_status
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              item.renewal_status ==
                                                                                              "Rejected"
                                                                                                ? _c(
                                                                                                    "v-chip",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                "red white--text",
                                                                                                            },
                                                                                                        },
                                                                                                        "v-chip",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            item.renewal_status
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.status
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      item.confirm_at !=
                                                                      null
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-space-between my-3",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " Client payment date : "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.convertTimeZone(
                                                                                          item.confirm_at
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.renewal_status ==
                                                                        "Pending" &&
                                                                      item.next_followup_at !=
                                                                        null
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-space-between my-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " Next follow up date : "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.convertTimeZone(
                                                                                          item.next_followup_at
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.renewal_status ==
                                                                        "Rejected" &&
                                                                      item.refund !=
                                                                        null
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-space-between my-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " Refund "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "font-weight-bold",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.refund ==
                                                                                          "1"
                                                                                          ? "Yes"
                                                                                          : "No"
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " Remark : "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "overflow-y":
                                                                                "auto",
                                                                            },
                                                                        },
                                                                        [
                                                                          item.remark !=
                                                                          null
                                                                            ? _c(
                                                                                "mavon-editor",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "min-height":
                                                                                        "100px",
                                                                                      height:
                                                                                        "auto",
                                                                                      width:
                                                                                        "100%",
                                                                                      "max-height":
                                                                                        "100px",
                                                                                      "overflow-y":
                                                                                        "auto",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      width:
                                                                                        "auto",
                                                                                      defaultOpen:
                                                                                        "preview",
                                                                                      toolbarsFlag: false,
                                                                                      subfield: false,
                                                                                      language:
                                                                                        "en",
                                                                                      editable: false,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        item.remark,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "remark",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "item.remark",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "mavon-editor",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "z-index":
                                                                                        "0",
                                                                                      "min-height":
                                                                                        "100px",
                                                                                      height:
                                                                                        "auto",
                                                                                      width:
                                                                                        "100%",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      height:
                                                                                        "auto",
                                                                                      width:
                                                                                        "auto",
                                                                                      defaultOpen:
                                                                                        "preview",
                                                                                      toolbarsFlag: false,
                                                                                      subfield: false,
                                                                                      language:
                                                                                        "en",
                                                                                      editable: false,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm.isRemarkNull,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.isRemarkNull =
                                                                                            $$v
                                                                                        },
                                                                                      expression:
                                                                                        "isRemarkNull",
                                                                                    },
                                                                                }
                                                                              ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-sheet",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center justify-center",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "25%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  _vm.readAllRenewalUpdate,
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Show all"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-end mt-2",
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click:
                                                    _vm.readAllRenewalUpdate,
                                                },
                                              },
                                              [_vm._v(" Show all ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-flex justify-start text-h7",
                                          },
                                          [_vm._v("No renewal status update")]
                                        ),
                                      ]),
                                  _c("hr", { staticClass: "my-7" }),
                                  _c("div", { staticClass: "my-3 mb-4" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start text-h6 mb-6",
                                        staticStyle: { "font-weight": "600" },
                                      },
                                      [_vm._v(" Refund status ")]
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _vm.isRefund
                                          ? _c("v-skeleton-loader", {
                                              ref: "skeleton",
                                              attrs: { type: "table" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.refund != null && !_vm.isRefund
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "px-4",
                                            staticStyle: {
                                              "border-radius": "8px",
                                              "border-style": "solid",
                                              "border-color":
                                                "rgba(5, 123, 166, 0.2)",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "elevation-0",
                                                staticStyle: {
                                                  height: "auto",
                                                  width: "auto",
                                                },
                                                attrs: {
                                                  flat: "",
                                                  "max-height": "400px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-carousel",
                                                      {
                                                        staticClass: "pa-3",
                                                        staticStyle: {
                                                          height: "auto",
                                                        },
                                                        attrs: {
                                                          "hide-delimiters": "",
                                                          max: "5",
                                                          continuous: false,
                                                          "max-height": "350px",
                                                          "max-width": "300px",
                                                        },
                                                      },
                                                      _vm._l(
                                                        this.refund,
                                                        function (item, index) {
                                                          return _c(
                                                            "v-carousel-item",
                                                            { key: index },
                                                            [
                                                              _c("v-sheet", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-start pa-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          index +
                                                                            1
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between my-4",
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        " Issue by "
                                                                      ),
                                                                    ]),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.user_name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between my-4",
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        " Issue Date "
                                                                      ),
                                                                    ]),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.convertTimeZone(
                                                                              item.created_at
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-space-between my-4",
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        " Refund Through : "
                                                                      ),
                                                                    ]),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.refund_type
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                item.refund_type ==
                                                                "Bank Transfer"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-space-between my-4",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " Bank "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item.bank_type
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                item.refund_type ==
                                                                "Bank Transfer"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-space-between my-4",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " Account No. "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item.bank_acc_no
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                item.refund_type ==
                                                                "Bank Transfer"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-space-between my-4",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " Account Holder "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item.bank_holder_name
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.isLoadingRefund ==
                                                                true
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _vm.isLoadingRefund
                                                                          ? _c(
                                                                              "v-progress-circular",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    size: 50,
                                                                                    color:
                                                                                      "primary",
                                                                                    indeterminate:
                                                                                      "",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c("div", [
                                                                      _vm.allowedRoleUpdateStatus.some(
                                                                        function (
                                                                          role
                                                                        ) {
                                                                          return _vm.$store.getters.getRole.includes(
                                                                            role
                                                                          )
                                                                        }
                                                                      )
                                                                        ? _c(
                                                                            "div",
                                                                            {},
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    " Status : "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-autocomplete",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      items:
                                                                                        _vm.refundOption,
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.validateInputChangeRefundStatus(
                                                                                          item.refund_id,
                                                                                          item.status
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        item.status,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "status",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "item.status",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex justify-space-between",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    " Status "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "mt-3",
                                                                                },
                                                                                [
                                                                                  item.status ==
                                                                                  "Pending"
                                                                                    ? _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "yellow",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                item.status
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  item.status ==
                                                                                  "Done"
                                                                                    ? _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "green white--text",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                item.status
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-flex justify-start text-h7",
                                            },
                                            [_vm._v(" No refund recorded ")]
                                          ),
                                        ]),
                                  ]),
                                  _c("hr", { staticClass: "my-6" }),
                                  _c(
                                    "div",
                                    { staticClass: "my-5 py-4" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-start text-h6 mt-4 mb-6",
                                          staticStyle: { "font-weight": "600" },
                                        },
                                        [_vm._v(" Latest payment status ")]
                                      ),
                                      _vm.isLoadingPayment
                                        ? _c("v-skeleton-loader", {
                                            ref: "skeleton",
                                            staticClass: "ma-4",
                                            attrs: { type: "card-avatar" },
                                          })
                                        : _vm._e(),
                                      _vm.latestPaymentForm != null
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "py-4 px-7",
                                              staticStyle: {
                                                "border-radius": "8px",
                                                "border-style": "solid",
                                                "border-color":
                                                  "rgba(5, 123, 166, 0.2)",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-space-between",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(" Report by : "),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.latestPaymentForm
                                                            .fname
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.latestPaymentForm
                                                            .lname
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-space-between",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v("Report date :"),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.convertTimeZone(
                                                            _vm
                                                              .latestPaymentForm
                                                              .created_at
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm.latestPaymentForm
                                                .total_amount != null
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between",
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          "Payment date :"
                                                        ),
                                                      ]),
                                                      _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.convertTimeZone(
                                                                _vm
                                                                  .latestPaymentForm
                                                                  .payment_at
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.latestPaymentForm
                                                .total_amount != null
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between",
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v("Amount : "),
                                                      ]),
                                                      _vm.latestPaymentForm
                                                        .total_amount != null
                                                        ? _c("p", [
                                                            _vm._v(
                                                              "RM " +
                                                                _vm._s(
                                                                  _vm
                                                                    .latestPaymentForm
                                                                    .total_amount
                                                                )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.latestPaymentForm
                                                .payment_method != null
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between",
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          "Payment method : "
                                                        ),
                                                      ]),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .latestPaymentForm
                                                              .payment_method
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-space-between",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v("Status : "),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    [
                                                      _vm.latestPaymentForm
                                                        .status == "Pending"
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "black--text",
                                                              attrs: {
                                                                color: "yellow",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .latestPaymentForm
                                                                      .status
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.latestPaymentForm
                                                        .status == "Success"
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "white--text",
                                                              attrs: {
                                                                color: "green",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .latestPaymentForm
                                                                      .status
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.latestPaymentForm
                                                        .status == "Fail"
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "white--text",
                                                              attrs: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .latestPaymentForm
                                                                      .status
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c("p", [_vm._v(" Remark ")]),
                                              _vm.latestPaymentForm.remark !=
                                              null
                                                ? _c("mavon-editor", {
                                                    staticStyle: {
                                                      "z-index": "0",
                                                      "max-height": "200px",
                                                      "overflow-y": "scroll",
                                                      "min-height": "100px",
                                                      height: "auto",
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      height: "auto",
                                                      width: "auto",
                                                      defaultOpen: "preview",
                                                      toolbarsFlag: false,
                                                      subfield: false,
                                                      language: "en",
                                                      editable: false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.latestPaymentForm
                                                          .remark,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.latestPaymentForm,
                                                          "remark",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "latestPaymentForm.remark",
                                                    },
                                                  })
                                                : _c("mavon-editor", {
                                                    staticStyle: {
                                                      "z-index": "0",
                                                      "min-height": "100px",
                                                      "max-height": "200px",
                                                      "overflow-y": "scroll",
                                                      height: "auto",
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      height: "auto",
                                                      width: "auto",
                                                      defaultOpen: "preview",
                                                      toolbarsFlag: false,
                                                      subfield: false,
                                                      language: "en",
                                                      editable: false,
                                                    },
                                                    model: {
                                                      value: _vm.isRemarkNull,
                                                      callback: function ($$v) {
                                                        _vm.isRemarkNull = $$v
                                                      },
                                                      expression:
                                                        "isRemarkNull",
                                                    },
                                                  }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.latestPaymentForm != null
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-end mt-4",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.readAllPaymentUpdate,
                                                  },
                                                },
                                                [_vm._v(" Show all ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isLoadingPayment &&
                                      _vm.latestPaymentForm == null
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-flex justify-start text-h7",
                                              },
                                              [
                                                _vm._v(
                                                  "No payment status update"
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("hr", { staticClass: "my-8 " }),
                              _c(
                                "div",
                                { staticClass: "my-5" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6 my-4 ",
                                      staticStyle: { "font-weight": "600" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" Receipt & Invoices "),
                                      ]),
                                    ]
                                  ),
                                  _vm.isLoadingAttachment
                                    ? _c("v-skeleton-loader", {
                                        ref: "skeleton",
                                        attrs: { type: "table" },
                                      })
                                    : _vm._e(),
                                  Object.keys(_vm.attachment).length != 0
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-5" },
                                        [
                                          _vm._l(
                                            this.attachment,
                                            function (item, index) {
                                              return _c("div", { key: index }, [
                                                index <= 2
                                                  ? _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-space-between",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-column",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "text-decoration-none",
                                                                      attrs: {
                                                                        href: item.attachment_url,
                                                                        target:
                                                                          "_blank",
                                                                        download:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.attachment_name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          "#" +
                                                                            (index +
                                                                              1)
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          item.attachment_type
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.convertTimeZone(
                                                                    item.created_at
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("hr", {
                                                        staticClass: " my-2",
                                                        staticStyle: {
                                                          color: "#F2F3F7",
                                                        },
                                                      }),
                                                    ])
                                                  : _vm._e(),
                                              ])
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-end pt-3",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    to: {
                                                      name: "PageAttachmentFormCompany",
                                                      params: {
                                                        id: this.$router.history
                                                          .current.params.id,
                                                      },
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Show All ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  Object.keys(_vm.attachment).length == 0 &&
                                  _vm.isLoadingAttachment == false
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-flex justify-start text-h7",
                                          },
                                          [
                                            _vm._v(
                                              " No Receipt or Invoices recorded "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("hr", { staticClass: "mt-6" }),
                      _c(
                        "v-row",
                        [
                          _c("v-col", {
                            staticClass: "px-7",
                            attrs: { cols: "12", sm: "12" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }